import React, { useState } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';

import Modal from '../Modal';
import JobDetail from '../JobDetail';

import styles from './JobPost.module.scss';

const JobPost = ({ job, demo }) => {
  const {
    position,
    company_name,
    primary_tag,
    extra_tags,
    apply_url,
    apply_email,
    post_date,
    location,
    remote_ok,
    highlighted,
  } = job;

  const [show, setShow] = useState(false);

  const apply = () => {
    if (apply_url) {
      if (!apply_url.startsWith('http')) {
        return `http://${apply_url}`;
      }
      return apply_url;
    }

    return `mailto:${apply_email}`;
  };

  const generateApply = () => {
    if (demo) {
      return (
        <button type="button" className="btn blue" disabled onClick={(e) => { e.stopPropagation(); }}>
          Apply
        </button>
      );
    }

    return (
      <a href={apply()} target="_blank" rel="noopener noreferrer" onClick={(e) => { e.stopPropagation(); }}>
        <button type="button" className="btn blue">
          Apply
        </button>
      </a>
    );
  };

  const toggleModal = () => {
    setShow(!show);
  };

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        className={[(highlighted ? styles.highlighted : ''), styles.job].join(' ')}
        onClick={toggleModal}
        onKeyPress={toggleModal}
      >
        <div className={styles.titles}>
          <div className={styles.position}>
            {position}
          </div>

          <div className={styles.companyName}>
            {company_name}
          </div>
        </div>

        <div className={styles.tags}>
          <span className={styles.tag}>
            {primary_tag.toUpperCase()}
          </span>
          {extra_tags.split(',').map((tag) => {
            if (tag.trim()) {
              return (<span key={tag} className={styles.tag}>{tag.trim().toUpperCase()}</span>);
            }
            return '';
          })}
        </div>

        <div className={styles.timestamps}>
          <div>
            {demo ? '1 day ago' : moment(post_date).fromNow()}
          </div>

          <div className={styles.location}>
            {location}
            {' '}
            {remote_ok ? (<span className={styles.remote}>(Remote)</span>) : ''}
          </div>
        </div>

        <div className={styles.apply}>
          {generateApply()}
        </div>

      </div>

      <Modal show={show} setShow={setShow}>
        <JobDetail data={job} demo={demo} />
      </Modal>
    </>
  );
};

JobPost.propTypes = {
  demo: propTypes.bool,
  job: propTypes.object.isRequired,
};

JobPost.defaultProps = { demo: false };

export default JobPost;
