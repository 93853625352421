export const getDashboard = async () => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch('/api/dashboard', options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const getProfile = async () => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch('/api/profile', options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};
