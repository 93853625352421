import React from 'react';

import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div>
        Copyright © 2019 Contracting.rocks. All rights reserved.
      </div>

      <div>
        Help/Support: support@contracting.rocks
      </div>
    </div>
  );
};

export default Footer;
