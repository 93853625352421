import { REGISTER, LOGIN, LOGOUT, REDIRECT, CHECK_EMAIL, UPDATE_USER } from '../actions/auth';

const initialState = {
  emailExists: false,
  redirectUrl: '',
};

let user = {};
const userString = window.localStorage.getItem('user');
try { user = JSON.parse(userString) || undefined; }
catch (err) { /* noop */ }
if (user) { initialState.user = user; }

const authReducer = (state = initialState, action) => {
  const { type } = action;
  const { payload } = action;

  switch (type) {
    case REGISTER:
      window.localStorage.setItem('user', JSON.stringify(payload));
      return Object.assign({}, state, { user: payload });
    case LOGIN:
      window.localStorage.setItem('user', JSON.stringify(payload));
      return Object.assign({}, state, { user: payload });
    case LOGOUT:
      window.localStorage.removeItem('user');
      return Object.assign({}, state, { user: undefined });
    case CHECK_EMAIL:
      return Object.assign({}, state, { emailExists: payload });
    case REDIRECT:
      return Object.assign({}, state, { redirecUrl: payload.redirectUrl });
    case UPDATE_USER:
      return Object.assign({}, state, { user: payload });
    default:
      return state;
  }
};

export default authReducer;
