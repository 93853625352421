import propTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { confirm } from '../../actions/auth';

const ConfirmAccount = ({ history, match }) => {
  const { token } = match.params;
  const [error, setError] = useState('');

  useEffect(() => {
    // do not return a promise in useEffect
    confirm(token)
      .then(() => { history.push('/dashboard'); })
      .catch((err) => { setError(err.message); });
  }, [history, token]);

  return (
    <div className="container">
      <p className="page-header">Confirming Account...</p>

      {error ? (<div className="error-block">{error}</div>) : ''}
    </div>
  );
};

ConfirmAccount.propTypes = {
  history: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
};

export default ConfirmAccount;
