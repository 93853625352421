import propTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import EditForm from './EditForm';
import { getJob } from '../../actions/jobs';
import JobPreview from '../../components/JobPreview';

import styles from './EditJob.module.scss';

const EditJob = ({ match }) => {
  const { id } = match.params;
  // job form
  const [formData, setFormData] = useState({
    id: 0,
    position: '',
    company_name: '',
    location: 'Anywhere',
    contract_length: '',
    remote_ok: false,
    primary_tag: 'dev',
    extra_tags: '',
    job_description: '',
    responsibilities: '',
    requirements: '',
    compensation: '',
    how_to_apply: '',
    apply_url: '',
    apply_email: '',
    invoice_notes: '',
    highlighted: true,
    sticky: true,
    sticky_length: 'week',
  });

  useEffect(() => {
    getJob(id).then((job) => { setFormData(job); });
  }, [id]);

  const setData = (property, value) => {
    setFormData(Object.assign({}, formData, { [property]: value }));
  };

  return (
    <div className={styles.split}>
      <div className={styles.half}>
        <EditForm
          data={formData}
          setData={setData}
        />
      </div>

      <div className={[styles.half, styles.sticky].join(' ')}>
        <JobPreview data={formData} />
      </div>
    </div>
  );
};

EditJob.propTypes = { match: propTypes.object.isRequired };

export default EditJob;
