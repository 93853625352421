import React from 'react';
import propTypes from 'prop-types';
import JobPost from '../JobPost';
import JobDetail from '../JobDetail';

import styles from './JobPreview.module.scss';

const JobPreview = ({ data }) => {
  return (
    <div className="container">
      <p className="page-header">Post Preview</p>

      <div className={styles.listingPreview}>
        <div className={styles.previewLead}>Listing Preview</div>

        <JobPost job={data} demo />
      </div>

      <div className={styles.detailPreview}>
        <div className={styles.previewLead}>Detail Preview</div>

        <JobDetail data={data} demo />
      </div>
    </div>
  );
};

JobPreview.propTypes = { data: propTypes.object.isRequired };

export default JobPreview;
