import React, { useState, useEffect } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import JobForm from './NewForm';
import JobPreview from '../../components/JobPreview';

import styles from './NewJob.module.scss';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE;

const NewJob = () => {
  // job form
  const [reload, setReload] = useState(false);
  const [jobFormData, setJobFormData] = useState({
    position: '',
    company_name: '',
    location: 'Anywhere',
    contract_length: '',
    remote_ok: false,
    primary_tag: 'dev',
    extra_tags: '',
    job_description: '',
    responsibilities: '',
    requirements: '',
    compensation: '',
    how_to_apply: '',
    apply_url: '',
    apply_email: '',
    invoice_notes: '',
    highlighted: true,
    sticky: true,
    sticky_length: 'week',
  });

  // reload page data
  useEffect(() => {
    try {
      const oldData = localStorage.getItem('newJobPost');
      const parsed = JSON.parse(oldData);
      if (parsed) { setJobFormData(parsed); }
      if (parsed) { setReload(true); }
    }
    catch (err) { /* noop */ }
  }, []);

  // stripe init
  const [stripe, setStripe] = useState(null);
  useEffect(() => {
    if (window.Stripe) { setStripe(window.Stripe(stripeKey)); }
    else {
      // Create Stripe instance once Stripe.js loads
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(stripeKey));
      });
    }
  }, []);

  const emptyForm = (newData) => {
    if (!newData.position
      && !newData.company_name
      && newData.primary_tag === 'dev'
      && !newData.extra_tags
      && !newData.job_description
      && !newData.responsibilities
      && !newData.requirements
      && newData.location === 'Anywhere'
      && newData.remote_ok === false
      && !newData.contract_length
      && !newData.compensation
      && !newData.how_to_apply
      && !newData.invoice_notes
      && !newData.apply_email
      && !newData.apply_url) { return true; }
    return false;
  };

  const setData = (property, value) => {
    const newData = Object.assign({}, jobFormData, { [property]: value });
    setJobFormData(newData);
    if (emptyForm(newData)) { localStorage.removeItem('newJobPost'); }
    else { localStorage.setItem('newJobPost', JSON.stringify(newData)); }
  };

  const clearData = () => {
    localStorage.removeItem('newJobPost');
  };

  return (
    <StripeProvider stripe={stripe}>
      <div className={styles.split}>
        <div className={styles.half}>
          <Elements>
            <JobForm
              reload={reload}
              data={jobFormData}
              setData={setData}
              clearData={clearData}
            />
          </Elements>
        </div>

        <div className={[styles.half, styles.sticky].join(' ')}>
          <JobPreview data={jobFormData} />
        </div>
      </div>
    </StripeProvider>
  );
};

export default NewJob;
