import { UPDATE_PRICES } from '../actions/prices';

const initialState = {
  basePrice: undefined,
  weekPrice: undefined,
  monthPrice: undefined,
  highlightPrice: undefined,
};

const pricesReducer = (state = initialState, action) => {
  const { type } = action;
  const { payload } = action;

  switch (type) {
    case UPDATE_PRICES:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
};

export default pricesReducer;
