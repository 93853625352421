import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Login from '../pages/Login';
import NewJob from '../pages/NewJob';
import Register from '../pages/Register';
import NotFound from '../pages/NotFound';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ConfirmAccount from '../pages/ConfirmAccount';
import Dashboard from '../pages/Dashboard';
import EditJob from '../pages/EditJob';

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset/:token" component={ResetPassword} />
      <Route exact path="/confirm/:token" component={ConfirmAccount} />
      <PrivateRoute exact path="/jobs/new" component={NewJob} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/jobs/edit/:id" component={EditJob} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
