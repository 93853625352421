/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import './styles/index.scss';
import Routes from './router/Routes';
import authReducer from './reducers/auth';
import pricesReducer from './reducers/prices';
import * as serviceWorker from './serviceWorker';
import Header from './components/Header';
import Footer from './components/Footer';

// enhancer for redux dev tools
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  // eslint-disable-next-line no-underscore-dangle
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;
const enhancer = composeEnhancers(applyMiddleware(ReduxThunk));

// combine reducers
const reducers = combineReducers({ auth: authReducer, prices: pricesReducer });
const store = createStore(reducers, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div className="page">
        <Header />

        <div className="page-content">
          <Routes />
        </div>

        <Footer />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
