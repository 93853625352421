import propTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { reset } from '../../actions/auth';
import Input from '../../components/Input';

import styles from './ResetPassword.module.scss';

const ResetPassword = ({ match }) => {
  const { token } = match.params;
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setLoginData] = useState({
    password: '',
    confirm: '',
  });

  const setData = (property, value) => {
    setLoginData(Object.assign({}, data, { [property]: value }));
  };

  // password
  const [passwordError, setPasswordError] = useState('');
  const passwordValidation = ({ target: { value } }) => {
    if (!value) { setPasswordError('Required'); }
    else { setPasswordError(''); }
  };

  // confirm
  const [confirmError, setConfirmError] = useState('');
  const confirmValidation = ({ target: { value } }) => {
    if (!value) { setConfirmError('Required'); }
    else if (value.length < 8) { setConfirmError('Password must be at least 8 characters'); }
    else if (value !== data.password) { setConfirmError('Passwords do not match'); }
    else { setConfirmError(''); }
  };

  // form validation
  const formValid = () => {
    return !confirmError
      && !passwordError
      && data.confirm
      && data.password;
  };

  // submit form
  const [submitError, setSubmitError] = useState('');
  const submit = () => {
    if (!formValid()) { return undefined; }

    return reset(token, data.password)
      .then(() => { setSuccessMessage('Your password was successfully reset!'); })
      .catch((err) => { setSubmitError(err.message); });
  };

  return (
    <div className="container">
      <p className="page-header">Reset Password</p>

      <Input
        autofocus
        name="password"
        type="password"
        display="Password"
        maxLength="255"
        value={data.password}
        setValue={setData}
        error={passwordError}
        validation={passwordValidation}
      />

      <Input
        name="confirm"
        type="password"
        display="Confirm Password"
        maxLength="255"
        value={data.confirm}
        setValue={setData}
        error={confirmError}
        validation={confirmValidation}
        onKeyPress={({ key }) => { if (key === 'Enter') { return submit(); } return undefined; }}
      />

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={!formValid()}
      >
        Login
      </button>

      {submitError ? (<div className="error-block">{submitError}</div>) : ''}
      {successMessage ? (
        <div className="success-block">
          <div className={styles.spaceBelow}>
            {successMessage}
          </div>

          <Link to="/login">Click Here to Login</Link>
        </div>
      ) : ''}
    </div>
  );
};

ResetPassword.propTypes = { match: propTypes.object.isRequired };

export default ResetPassword;
