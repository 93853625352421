/**
 * ACTIONS
 */
export const UPDATE_PRICES = 'UPDATE_PRICES';

/**
 * Action Creators
 */
export const updatePrices = () => {
  const options = { method: 'GET' };

  return async (dispatch) => {
    const response = await fetch('/api/prices', options);
    const body = await response.json();

    if (!response) { return Promise.reject(new Error('Connection Failed')); }
    if (response.status === 401) { return Promise.reject(new Error(body.message)); }
    if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

    return dispatch({
      type: UPDATE_PRICES,
      payload: body,
    });
  };
};
