export const getJobs = async (lastId) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  let url = '/api/jobs';
  if (lastId) { url += `?lastId=${lastId}`; }

  const response = await fetch(url, options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const getJob = async (id) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch(`/api/jobs/${id}`, options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const deactivate = async (jobId) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  const url = `/api/jobs/deactivate/${jobId}`;
  const response = await fetch(url, options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const activate = async (jobId) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  const url = `/api/jobs/activate/${jobId}`;
  const response = await fetch(url, options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};
