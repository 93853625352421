import propTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { updatePrices } from '../../../actions/prices';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import SwitchInput from '../../../components/SwitchInput';
import Toggle from '../../../components/Toggle';

import styles from './NewForm.module.scss';

const JobForm = (props) => {
  const {
    data,
    setData,
    clearData,
    stripe,
    history,
    reload,
  } = props;
  const dispatch = useDispatch();
  const [card, setCard] = useState(null);
  const [price, setPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // update plan prices internally
  useEffect(() => { dispatch(updatePrices()).catch(() => { /* noop */ }); }, [dispatch]);
  const prices = useSelector((store) => { return store.prices; });

  // update current price on data change
  useEffect(() => {
    let currentPrice = prices.basePrice;
    if (data.highlighted) { currentPrice += prices.highlightPrice; }
    if (data.sticky && data.sticky_length === 'week') { currentPrice += prices.weekPrice; }
    else if (data.sticky && data.sticky_length === 'month') { currentPrice += prices.monthPrice; }
    if (!currentPrice) { setErrors(Object.assign({}, errors, { stripe: '' })); }
    setPrice(currentPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, prices]);

  // control validations
  const validate = useCallback((e, newValue, meta) => {
    const controlErrors = {};

    if (typeof e === 'object') {
      const { name, value } = e.target;
      if (!value) { controlErrors[name] = 'Required'; }
      else { controlErrors[name] = ''; }
    }

    if (typeof e === 'string' && e === 'stripe') {
      if (!newValue || !newValue.complete) { controlErrors.stripe = 'Required'; }
      else { controlErrors.stripe = ''; }
    }

    if (typeof e === 'string' && e === 'apply') {
      if (!newValue) { controlErrors.apply = 'Required'; }
      else if (meta === 'email' && !/\S+@\S+\.\S+/.test(newValue)) { controlErrors.apply = 'Invalid Format'; }
      else { controlErrors.apply = ''; }
    }

    setErrors((prevState) => {
      return Object.assign({}, prevState, controlErrors);
    });
  }, []);

  const revalidate = () => {
    const reloadErrors = {};
    if (!data.position) { reloadErrors.position = 'Required'; }
    if (!data.company_name) { reloadErrors.company_name = 'Required'; }
    if (!data.primary_tag) { reloadErrors.primary_tag = 'Required'; }
    if (!data.extra_tags) { reloadErrors.extra_tags = 'Required'; }
    if (!data.job_description) { reloadErrors.job_description = 'Required'; }
    if (!data.location) { reloadErrors.location = 'Required'; }
    if (!data.contract_length) { reloadErrors.contract_length = 'Required'; }
    if (!data.apply_url && !data.apply_email) { reloadErrors.apply = 'Required'; }
    if (data.apply_email && !/\S+@\S+\.\S+/.test(data.apply_email)) { reloadErrors.apply = 'Invalid Format'; }
    if (price) {
      if (!card || !card.complete) { reloadErrors.stripe = 'Required'; }
    }

    setErrors(Object.assign({}, reloadErrors));
  };

  // reload validations
  useEffect(() => {
    if (!reload) { return; }
    revalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  // stripe handler
  const stripeHandler = (updatedCard) => {
    setCard(updatedCard);
    validate('stripe', updatedCard);
  };

  const stripeDisplay = () => {
    if (!price) { return ''; }

    return (
      <>
        <hr className={styles.rule} />

        <p className="page-header">Payment</p>

        <div className={styles.stripe}>
          <div className={styles.stripeLead}>
            Company Card
            {' '}
            {errors.stripe ? (<span className="error-output">{errors.stripe}</span>) : ''}
          </div>

          <div className={[styles.card, (errors.stripe ? styles.error : '')].join(' ')}>
            <CardElement
              onChange={stripeHandler}
              onBlur={() => { validate('stripe', card); }}
            />
          </div>
        </div>
      </>
    );
  };

  // primary tag
  const primaryTags = [
    { value: 'dev', display: 'Software Development' },
    { value: 'design', display: 'Design' },
    { value: 'frontend', display: 'Frontend' },
    { value: 'backend', display: 'Backend' },
    { value: 'nontech', display: 'Non-Technical' },
  ];

  // form validation
  const formValid = () => {
    // validate apply_email
    if (data.apply_email && !/\S+@\S+\.\S+/.test(data.apply_email)) { return false; }

    // validate sticky and sticky length
    if (data.sticky && !data.sticky_length) { return false; }

    // validate require on all required fields
    const hasErrors = Object.values(errors).some((error) => { return !!error; });
    return !hasErrors && (data.apply_url || data.apply_email) && (price ? (card && card.complete) : true);
  };

  // submit job
  const [submitError, setSubmitError] = useState('');
  const submit = async () => {
    // validation check
    if (!formValid()) {
      revalidate();
      setSubmitError('Some fields are still required');
      return;
    }

    // prevent accidental double submission
    if (submitted) { return; }
    setSubmitted(true);

    // generate stripe token
    const token = {};
    if (!price) { token.id = 'discount'; } // august special
    else { token.id = (await stripe.createToken({ name: data.company_name })).token.id; }

    // send job data to backend
    try {
      const response = await fetch('/api/purchases', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Object.assign({}, { data }, { token: token.id }, { price })),
      });

      // redirect user to dashboard
      if (response.ok) {
        clearData();
        history.push('/dashboard');
      }
      // or display error from server
      else {
        const respBody = await response.json();
        setSubmitted(false);
        setSubmitError(respBody.message);
      }
    }
    catch (err) {
      setSubmitted(false);
      setSubmitError(err.message);
    }
  };

  return (
    <div className="container">
      <p className="page-header">Create A Job Post</p>

      <Input
        autofocus
        type="text"
        name="position"
        display="Position"
        value={data.position}
        setValue={setData}
        error={errors.position}
        validation={validate}
      />

      <Input
        type="text"
        name="company_name"
        display="Company Name"
        value={data.company_name}
        setValue={setData}
        error={errors.company_name}
        validation={validate}
      />

      <Select
        name="primary_tag"
        display="Primary Tag"
        options={primaryTags}
        value={data.primary_tag}
        setValue={setData}
        error={errors.primary_tag}
        validation={validate}
      />

      <Input
        name="extra_tags"
        type="text"
        display="Extra Tags"
        subLabel="Separate multiple tags by comma (ex. javascript)"
        value={data.extra_tags}
        setValue={setData}
        error={errors.extra_tags}
        validation={validate}
      />

      <TextArea
        name="job_description"
        display="Job Description"
        value={data.job_description}
        setValue={setData}
        error={errors.job_description}
        validation={validate}
      />

      <TextArea
        name="responsibilities"
        display="Responsibilities"
        value={data.responsibilities}
        setValue={setData}
      />

      <TextArea
        name="requirements"
        display="Requirements"
        value={data.requirements}
        setValue={setData}
      />

      <Input
        type="text"
        name="location"
        display="Location"
        subLabel="Restrict your contract by location"
        value={data.location}
        setValue={setData}
        error={errors.location}
        validation={validate}
      />

      <Checkbox
        name="remote_ok"
        display="Remote OK?"
        value={data.remote_ok}
        setValue={setData}
      />

      <Input
        type="text"
        name="contract_length"
        display="Contract Length"
        value={data.contract_length}
        setValue={setData}
        error={errors.contract_length}
        validation={validate}
      />

      <Input
        type="text"
        name="compensation"
        display="Compensation"
        value={data.compensation}
        setValue={setData}
      />

      <TextArea
        name="how_to_apply"
        display="How to Apply"
        value={data.how_to_apply}
        setValue={setData}
      />

      <SwitchInput
        data={data}
        name="switch_input"
        setValue={setData}
        error={errors.apply}
        validation={validate}
      />

      <hr className={styles.rule} />

      <p className="page-header">Extras</p>

      <Checkbox
        name="highlighted"
        display={`Hightlight post in Yellow (+$${prices.highlightPrice})`}
        value={data.highlighted}
        setValue={setData}
        reverse
      />

      <Checkbox
        name="sticky"
        display="Display my post at the top of homepage"
        value={data.sticky}
        setValue={setData}
        reverse
      />

      <Toggle
        name="sticky_length"
        options={[{ id: 'week', price: prices.weekPrice }, { id: 'month', price: prices.monthPrice }]}
        disabled={!data.sticky}
        value={data.sticky_length}
        setValue={setData}
      />

      {stripeDisplay()}

      <hr className={styles.rule} />

      <p className="page-header">
        Final Price:
        {' '}
        {`$${price}`}
      </p>

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={submitted}
      >
        Submit Job
      </button>

      {submitError ? (<div className="error-block">{submitError}</div>) : ''}
    </div>
  );
};

JobForm.propTypes = {
  stripe: propTypes.object,
  setData: propTypes.func.isRequired,
  clearData: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  reload: propTypes.bool.isRequired,
};

JobForm.defaultProps = { stripe: {} };

export default injectStripe(withRouter(JobForm));
