import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import styles from './Modal.module.scss';

const Modal = ({ children, show, setShow }) => {
  useEffect(() => {
    if (show) { document.body.setAttribute('style', 'overflow: hidden'); }
    else { document.body.setAttribute('style', 'overflow: auto'); }
  }, [show]);

  const closeModal = () => {
    setShow(false);
  };

  if (!show) { return ''; }
  return (
    <div className={styles.modal}>
      <div role="button" tabIndex="-1" className={styles.background} onClick={closeModal} onKeyPress={closeModal} />

      <div className={styles.content}>
        <div className={styles.controls}>
          <button type="button" className="btn blue" onClick={closeModal}>
            Close
          </button>
        </div>

        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  children: propTypes.object.isRequired,
};

export default Modal;
