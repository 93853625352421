import propTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

/*
 * Parent state should manage and pass the input value and input error string from it's state.
 * Parent is required to declare a validation function that sets the error and passes it
 * down to the this component.
 * Parent is required to pass down a function that updates the value in the parent state.
 *
 * Required:
 * value (string)
 * setValue (func)
 */
const TextArea = (props) => {
  const {
    name,
    display,
    autofocus = false,
    maxLength = '500000',
    value,
    setValue,
    error = '',
    validation = () => { },
    onKeyPress = () => { },
  } = props;

  // textarea focus
  const textareaRef = useRef(null);
  useEffect(() => {
    if (autofocus) { textareaRef.current.focus(); }
  }, [autofocus]);

  // textarea change
  const handleChange = (e) => {
    const textValue = e.target.value;
    setValue(name, textValue);
    validation(e);
  };

  // textarea blur
  const handleBlur = (e) => {
    const textValue = e.target.value.trim();
    if (value !== textValue) { setValue(name, textValue); }
    validation(e);
  };

  return (
    <label htmlFor={name}>
      {display}
      {' '}
      {error ? (<span className="error-output">{error}</span>) : ''}
      <textarea
        name={name}
        maxLength={maxLength}
        className={(error) ? 'error' : ''}
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
      />
    </label>
  );
};

TextArea.propTypes = {
  name: propTypes.string.isRequired,
  display: propTypes.string.isRequired,
  autofocus: propTypes.bool,
  maxLength: propTypes.string,
  value: propTypes.string.isRequired,
  setValue: propTypes.func.isRequired,
  error: propTypes.string,
  validation: propTypes.func,
  onKeyPress: propTypes.func,
};

TextArea.defaultProps = {
  autofocus: false,
  maxLength: '500000',
  error: '',
  validation: () => { },
  onKeyPress: () => { },
};

export default TextArea;
