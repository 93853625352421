import propTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';
import styles from './Header.module.scss';


const Header = ({ history, location }) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => { return store.auth.user; });
  const loggedIn = !!user;

  const logoutHandler = () => {
    dispatch(logout()).catch(() => { /* noop */ });
  };

  const loginHandler = () => {
    if (location.pathname !== '/login') { history.push('/login'); }
  };

  const registerHandler = () => {
    if (location.pathname !== '/register') { history.push('/register'); }
  };

  const newJobHandler = () => {
    if (location.pathname !== '/jobs/new') { history.push('/jobs/new'); }
  };

  const dashboardHandler = () => {
    if (location.pathname !== '/dashboard') { history.push('/dashboard'); }
  };

  const generateControls = () => {
    let postButton = '';
    if (loggedIn && user.confirmed) {
      postButton = (
        <button
          type="button"
          className={[styles.btn, 'btn', 'blue'].join(' ')}
          onClick={newJobHandler}
        >
          Post a Job
        </button>
      );
    }

    if (loggedIn) {
      return (
        <div className={styles.controls}>
          <button
            type="button"
            className={[styles.btn, 'btn', 'outline'].join(' ')}
            onClick={dashboardHandler}
          >
            Profile
          </button>

          {postButton}

          <button
            type="button"
            className={[styles.btn, 'btn', 'outline'].join(' ')}
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      );
    }

    return (
      <div className={styles.controls}>
        <button
          type="button"
          className={[styles.btn, 'btn', 'outline'].join(' ')}
          onClick={loginHandler}
        >
          Login
        </button>

        <button
          type="button"
          className={[styles.btn, 'btn', 'blue'].join(' ')}
          onClick={registerHandler}
        >
          Register
        </button>
      </div>
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          Contracting.Rocks!
        </Link>
      </div>

      {generateControls()}
    </div>
  );
};

Header.propTypes = {
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
};

export default withRouter(Header);
