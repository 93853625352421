import propTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import SwitchInput from '../../../components/SwitchInput';

import styles from './EditForm.module.scss';

const JobForm = (props) => {
  const { data, setData, history } = props;
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // control validations
  const validate = useCallback((e, newValue, meta) => {
    const controlErrors = {};

    if (typeof e === 'object') {
      const { name, value } = e.target;
      if (!value) { controlErrors[name] = 'Required'; }
      else { controlErrors[name] = ''; }
    }

    if (typeof e === 'string' && e === 'apply') {
      if (!newValue) { controlErrors.apply = 'Required'; }
      else if (meta === 'email' && !/\S+@\S+\.\S+/.test(newValue)) { controlErrors.apply = 'Invalid Format'; }
      else { controlErrors.apply = ''; }
    }

    setErrors((prevState) => {
      return Object.assign({}, prevState, controlErrors);
    });
  }, []);

  const revalidate = () => {
    const reloadErrors = {};
    if (!data.position) { reloadErrors.position = 'Required'; }
    if (!data.company_name) { reloadErrors.company_name = 'Required'; }
    if (!data.primary_tag) { reloadErrors.primary_tag = 'Required'; }
    if (!data.extra_tags) { reloadErrors.extra_tags = 'Required'; }
    if (!data.job_description) { reloadErrors.job_description = 'Required'; }
    if (!data.location) { reloadErrors.location = 'Required'; }
    if (!data.contract_length) { reloadErrors.contract_length = 'Required'; }
    if (!data.apply_url && !data.apply_email) { reloadErrors.apply = 'Required'; }
    if (data.apply_email && !/\S+@\S+\.\S+/.test(data.apply_email)) { reloadErrors.apply = 'Invalid Format'; }

    setErrors(Object.assign({}, reloadErrors));
  };

  // primary tag
  const primaryTags = [
    { value: 'dev', display: 'Software Development' },
    { value: 'design', display: 'Design' },
    { value: 'frontend', display: 'Frontend' },
    { value: 'backend', display: 'Backend' },
    { value: 'nontech', display: 'Non-Technical' },
  ];

  // form validation
  const formValid = () => {
    // validate apply_email
    if (data.apply_email && !/\S+@\S+\.\S+/.test(data.apply_email)) { return false; }

    // validate require on all required fields
    const hasErrors = Object.values(errors).some((error) => { return !!error; });
    return !hasErrors && (data.apply_url || data.apply_email);
  };

  // submit job
  const [submitError, setSubmitError] = useState('');
  const submit = async () => {
    // validation check
    if (!formValid()) {
      revalidate();
      setSubmitError('Some fields are still required');
      return;
    }

    // prevent accidental double submission
    if (submitted) { return; }
    setSubmitted(true);

    // send job data to backend
    try {
      const response = await fetch(`/api/jobs/${data.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(Object.assign({}, data)),
      });

      // redirect user to dashboard
      if (response.ok) { history.push('/dashboard'); }
      // or display error from server
      else {
        const respBody = await response.json();
        setSubmitted(false);
        setSubmitError(respBody.message);
      }
    }
    catch (err) {
      setSubmitted(false);
      setSubmitError(err.message);
    }
  };

  return (
    <div className="container">
      <p className="page-header">Editing Job Post</p>

      <Input
        autofocus
        type="text"
        name="position"
        display="Position"
        value={data.position}
        setValue={setData}
        error={errors.position}
        validation={validate}
      />

      <Input
        type="text"
        name="company_name"
        display="Company Name"
        value={data.company_name}
        setValue={setData}
        error={errors.company_name}
        validation={validate}
      />

      <Select
        name="primary_tag"
        display="Primary Tag"
        options={primaryTags}
        value={data.primary_tag}
        setValue={setData}
        error={errors.primary_tag}
        validation={validate}
      />

      <Input
        name="extra_tags"
        type="text"
        display="Extra Tags"
        subLabel="Separate multiple tags by comma (ex. javascript)"
        value={data.extra_tags}
        setValue={setData}
        error={errors.extra_tags}
        validation={validate}
      />

      <TextArea
        name="job_description"
        display="Job Description"
        value={data.job_description}
        setValue={setData}
        error={errors.job_description}
        validation={validate}
      />

      <TextArea
        name="responsibilities"
        display="Responsibilities"
        value={data.responsibilities}
        setValue={setData}
      />

      <TextArea
        name="requirements"
        display="Requirements"
        value={data.requirements}
        setValue={setData}
      />

      <Input
        type="text"
        name="location"
        display="Location"
        subLabel="Restrict your contract by location"
        value={data.location}
        setValue={setData}
        error={errors.location}
        validation={validate}
      />

      <Checkbox
        name="remote_ok"
        display="Remote OK?"
        value={data.remote_ok}
        setValue={setData}
      />

      <Input
        type="text"
        name="contract_length"
        display="Contract Length"
        value={data.contract_length}
        setValue={setData}
        error={errors.contract_length}
        validation={validate}
      />

      <Input
        type="text"
        name="compensation"
        display="Compensation"
        value={data.compensation}
        setValue={setData}
      />

      <TextArea
        name="how_to_apply"
        display="How to Apply"
        value={data.how_to_apply}
        setValue={setData}
      />

      <SwitchInput
        data={data}
        name="switch_input"
        setValue={setData}
        error={errors.apply}
        validation={validate}
      />

      <hr className={styles.rule} />

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={submitted}
      >
        Edit Post
      </button>

      {submitError ? (<div className="error-block">{submitError}</div>) : ''}
    </div>
  );
};

JobForm.propTypes = {
  setData: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};

export default withRouter(JobForm);
