import propTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { getJobs } from '../../actions/jobs';
import JobPost from '../../components/JobPost';

import styles from './Home.module.scss';

const Home = ({ history }) => {
  const [jobs, setJobs] = useState([]);
  const [stickies, setStickies] = useState([]);
  const [hasMoreJobs, setHasMoreJobs] = useState(true);

  // load initial jobs
  useEffect(() => {
    getJobs()
      .then((response) => {
        // update stickies
        setStickies([...response.stickies]);
        // update regular jobs
        setJobs([...response.jobs]);
      })
      .catch(() => { /* noop */ });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load more jobs
  const loadMoreJobs = async () => {
    let lastJobId = 0;
    jobs.forEach((currentJob) => {
      if (currentJob.id > lastJobId) { lastJobId = currentJob.id; }
    });

    const moreJobs = await getJobs(lastJobId);

    // check to see if there are more jobs in the db
    if (moreJobs.length) {
      setJobs((oldJobs) => {
        return [...oldJobs, ...moreJobs];
      });
    }
    else { setHasMoreJobs(false); }
  };

  // register CTA
  const register = () => {
    history.push('/register');
  };

  // august special
  const special = () => {
    const now = new Date();
    const august = new Date('2019-09-01');
    if (now < august) {
      return (
        <div className={styles.special}>
          All posts free until the end of August
        </div>
      );
    }
    return '';
  };
  // end august special

  return (
    <>
      <div className={styles.pageHeader}>
        <div className={styles.split}>
          <div className={styles.employees}>
            Contractors:
            <ul>
              <li>Find work that match your skills</li>
              <li>Work on your schedule</li>
            </ul>
          </div>

          <div className={styles.employers}>
            Employers:
            <ul>
              <li>Post Contracts for only $50</li>
              <li>Keep track of all contracts posted</li>
            </ul>
          </div>
        </div>

        {special()}

        <div className={styles.register}>
          <button type="button" className="btn blue outline" onClick={register}>
            Create an Account to Post Contracts Now
          </button>
        </div>
      </div>

      <div className="container">
        <div className={styles.sticky}>
          Recent Jobs:
        </div>

        {
          stickies.map((job) => {
            return (
              <div key={job.id} className={styles.jobs}>
                <JobPost job={job} />
              </div>
            );
          })
        }

        {
          jobs.map((job) => {
            return (
              <div key={job.id} className={styles.jobs}>
                <JobPost job={job} />
              </div>
            );
          })
        }

        <div className={styles.moreJobs}>
          {
            hasMoreJobs ? (
              <button type="button" className="btn blue full" onClick={loadMoreJobs}>
                Load More Jobs
              </button>
            ) : ''
          }
        </div>
      </div>
    </>
  );
};

Home.propTypes = { history: propTypes.object.isRequired };

export default Home;
