import propTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

/*
 * Parent state should manage and pass the input value and input error string from it's state.
 * Parent is required to declare a validation function that sets the error and passes it
 * down to the this component.
 * Parent is required to pass down a function that updates the value in the parent state.
 *
 * Required:
 * value (string)
 * setValue (func)
 */
const Input = (props) => {
  const {
    name,
    type,
    display,
    subLabel,
    autofocus = false,
    maxLength = '255',
    value,
    setValue,
    error = '',
    validation = () => { },
    onKeyPress = () => { },
  } = props;

  // input focus
  const inputRef = useRef(null);
  useEffect(() => {
    if (autofocus) { inputRef.current.focus(); }
  }, [autofocus]);

  // input change
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setValue(name, inputValue);
    validation(e);
  };

  // input blur
  const handleBlur = (e) => {
    const inputValue = e.target.value.trim();
    if (value !== inputValue) { setValue(name, inputValue); }
    validation(e);
  };

  return (
    <label htmlFor={name}>
      {display}
      {' '}
      {error ? (<span className="error-output">{error}</span>) : ''}
      {subLabel ? (<div className="sub-label">{subLabel}</div>) : ''}
      <input
        id={name}
        type={type}
        name={name}
        maxLength={maxLength}
        className={(error) ? 'error' : ''}
        ref={inputRef}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
      />
    </label>
  );
};

Input.propTypes = {
  type: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  display: propTypes.string.isRequired,
  subLabel: propTypes.string,
  autofocus: propTypes.bool,
  maxLength: propTypes.string,
  value: propTypes.string.isRequired,
  setValue: propTypes.func.isRequired,
  error: propTypes.string,
  validation: propTypes.func,
  onKeyPress: propTypes.func,
};

Input.defaultProps = {
  subLabel: '',
  autofocus: false,
  maxLength: '255',
  error: '',
  validation: () => { },
  onKeyPress: () => { },
};

export default Input;
