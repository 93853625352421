import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../actions/auth';
import { getDashboard, getProfile } from '../../actions/users';
import JobPost from '../../components/JobPost';
import { deactivate, activate } from '../../actions/jobs';

import styles from './Dashboard.module.scss';

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState({});
  const [confirmError, setConfirmError] = useState('');

  useEffect(() => {
    getDashboard()
      .then((response) => { setJobs(response); })
      // catch should get an error object, and on 401 redirect
      .catch(() => { history.push('/login'); });
  }, [history]);

  useEffect(() => {
    getProfile()
      .then((response) => {
        if (!response.confirmed) {
          setConfirmError('Confirm your account before creating a Job Post');
        }
        setProfile(response);
        return dispatch(updateUser(response));
      })
      // catch should get an error object, and on 401 redirect
      .catch(() => { history.push('/login'); });
  }, [history, dispatch]);

  const toggleJob = async (job) => {
    const enabled = job.job_status === 1;

    // handle errors
    let updatedJob;
    try {
      if (enabled) { updatedJob = await deactivate(job.id); }
      else { updatedJob = await activate(job.id); }
    }
    catch (err) {
      return setErrors(Object.assign({}, errors, { [job.id]: err.message }));
    }

    // clear any errors
    setErrors(Object.assign({}, errors, { [job.id]: '' }));

    // update job locally
    const foundJob = jobs.find((currentJob) => { return currentJob.id === updatedJob.id; });
    foundJob.job_status = updatedJob.job_status;
    return setJobs([...jobs]);
  };

  const createJob = (job) => {
    const enabled = job.job_status === 1;

    return (
      <div key={job.id} className={styles.job}>
        <div>
          <JobPost job={job} />
        </div>

        <div className={styles.jobControls}>
          <button
            type="button"
            className={['btn', 'full', enabled ? 'red' : 'blue'].join(' ')}
            onClick={() => { toggleJob(job); }}
          >
            {enabled ? 'Disable' : 'Enable'}
          </button>

          <button
            type="button"
            className="btn blue full"
            onClick={() => { history.push(`/jobs/edit/${job.id}`); }}
          >
            Edit
          </button>
        </div>

        {errors[job.id] ? (<div className="error-block">{errors[job.id]}</div>) : ''}
      </div>
    );
  };

  const createNoJobs = () => {
    let body = '';
    if (profile.confirmed) {
      body = (
        <div className={styles.noJobBody}>
          <button
            type="button"
            className="btn blue"
            onClick={() => { history.push('/jobs/new'); }}
          >
            Post a Job
          </button>
        </div>
      );
    }

    return (
      <div className={styles.job}>
        <div className={styles.noJobHeader}>
          No Jobs Found
        </div>

        {body}
      </div>
    );
  };

  return (
    <div className={styles.dashboard}>
      {confirmError ? (<div className="error-block">{confirmError}</div>) : ''}

      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          Profile
        </div>

        <div className={styles.profile}>
          <div className={styles.profileHeader}>
            Email
          </div>

          <div className={styles.profileBody}>
            {profile.email}
          </div>
        </div>

        <div className={styles.profile}>
          <div className={styles.profileHeader}>
            Account Status
          </div>

          <div className={styles.profileBody}>
            {profile.confirmed ? 'Confirmed' : 'Not Confirmed'}
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          Job Posts
        </div>

        {jobs.length ? jobs.map(createJob) : createNoJobs()}
      </div>
    </div>
  );
};

Dashboard.propTypes = { history: propTypes.object.isRequired };

export default Dashboard;
