import React, { useState } from 'react';
import { forgot } from '../../actions/auth';
import Input from '../../components/Input';

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);
  const [data, setLoginData] = useState({ email: '' });
  const [successMessage, setSuccessMessage] = useState('');

  // email
  const [emailError, setEmailError] = useState('');
  const emailValidation = ({ target: { value } }) => {
    if (!/\S+@\S+\.\S+/.test(value)) { setEmailError('Invalid Format'); }
    else { setEmailError(''); }
  };

  // email input handler
  const setData = (property, value) => {
    setLoginData(Object.assign({}, data, { [property]: value }));
  };

  // form validation
  const formValid = () => {
    return !emailError && data.email;
  };

  // submit form
  const [error, setError] = useState('');
  const submit = () => {
    if (!formValid()) { return undefined; }

    // prevent accidental double submission
    if (submitted) { return undefined; }
    setSubmitted(true);

    return forgot(data.email)
      .then(() => { setSuccessMessage('An email was sent with a link to reset your password'); })
      .catch((err) => { setError(err.message); });
  };

  return (
    <div className="container">
      <p className="page-header">Forgot Password</p>

      <Input
        name="email"
        type="email"
        display="Email"
        autofocus
        maxLength="255"
        value={data.email}
        setValue={setData}
        error={emailError}
        validation={emailValidation}
        onKeyPress={({ key }) => { if (key === 'Enter') { return submit(); } return undefined; }}
      />

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={!formValid() || submitted}
      >
        Login
      </button>

      {error ? (<div className="error-block">{error}</div>) : ''}
      {successMessage ? (<div className="success-block">{successMessage}</div>) : ''}
    </div>
  );
};

export default ForgotPassword;
