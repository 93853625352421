import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../../actions/auth';
import Input from '../../components/Input';

import styles from './Login.module.scss';

const Login = ({ location, history }) => {
  const dispatch = useDispatch();
  const { from } = location.state || { from: { pathname: '/dashboard' } };

  const [data, setLoginData] = useState({ email: '', password: '' });

  const setData = (property, value) => {
    setLoginData(Object.assign({}, data, { [property]: value }));
  };

  // email
  const [emailError, setEmailError] = useState('');
  const emailValidation = ({ target: { value } }) => {
    if (!/\S+@\S+\.\S+/.test(value)) { setEmailError('Invalid Format'); }
    else { setEmailError(''); }
  };

  // password
  const [passwordError, setPasswordError] = useState('');
  const passwordValidation = ({ target: { value } }) => {
    if (!value) { setPasswordError('Required'); }
    else { setPasswordError(''); }
  };

  // form validation
  const formValid = () => {
    return !emailError
      && !passwordError
      && data.email
      && data.password;
  };

  // submit form
  const [error, setError] = useState('');
  const submit = () => {
    if (!formValid()) { return undefined; }

    return dispatch(login(data.email, data.password))
      .then(() => { history.push(from); })
      .catch((err) => { setError(err.message); });
  };

  return (
    <div className="container">
      <p className="page-header">Please Login</p>

      <Input
        name="email"
        type="email"
        display="Email"
        autofocus
        maxLength="255"
        value={data.email}
        setValue={setData}
        error={emailError}
        validation={emailValidation}
      />

      <Input
        name="password"
        type="password"
        display="Password"
        maxLength="255"
        value={data.password}
        setValue={setData}
        error={passwordError}
        validation={passwordValidation}
        onKeyPress={({ key }) => { if (key === 'Enter') { submit(); } }}
      />

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={!formValid()}
      >
        Login
      </button>

      {error ? (<div className="error-block">{error}</div>) : ''}

      <div className={styles.forgot}>
        <Link to="/forgot-password">
          Forgot Password?
        </Link>
      </div>

    </div>
  );
};

Login.propTypes = {
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
};

export default Login;
