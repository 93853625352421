import propTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

/*
 * Parent state should manage and pass the input value and input error string from it's state.
 * Parent is required to declare a validation function that sets the error and passes it
 * down to the this component.
 * Parent is required to pass down a function that updates the value in the parent state.
 *
 * Required:
 * value (string)
 * setValue (func)
 */
const Checkbox = (props) => {
  const {
    name,
    display,
    autofocus = false,
    value,
    setValue,
    reverse,
  } = props;

  // input focus
  const inputRef = useRef(null);
  useEffect(() => {
    if (autofocus) { inputRef.current.focus(); }
  }, [autofocus]);

  // input change
  const handleChange = ({ target: { checked } }) => {
    setValue(name, checked);
  };

  return (
    <div>
      <label htmlFor={name}>
        {reverse ? '' : display}
        <input
          id={name}
          type="checkbox"
          name={name}
          ref={inputRef}
          checked={value}
          onChange={handleChange}
        />
        {reverse ? display : ''}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: propTypes.string.isRequired,
  display: propTypes.string.isRequired,
  autofocus: propTypes.bool,
  value: propTypes.bool.isRequired,
  setValue: propTypes.func.isRequired,
  reverse: propTypes.bool,
};

Checkbox.defaultProps = {
  autofocus: false,
  reverse: false,
};

export default Checkbox;
