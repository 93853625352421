import propTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

/*
 * Parent state should manage and pass the input value and input error string from it's state.
 * Parent is required to declare a validation function that sets the error and passes it
 * down to the this component.
 * Parent is required to pass down a function that updates the value in the parent state.
 *
 * Required:
 * value (string)
 * setValue (func)
 */
const Select = (props) => {
  const {
    name,
    display,
    autofocus = false,
    options,
    value,
    setValue,
    error = '',
    validation = () => { },
  } = props;

  // select focus
  const selectRef = useRef(null);
  useEffect(() => {
    if (autofocus) { selectRef.current.focus(); }
  }, [autofocus]);

  // select change
  const handleChange = (e) => {
    const selectValue = e.target.value;
    setValue(name, selectValue);
    validation(e);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={name}>
      {display}
      {' '}
      {error ? (<span className="error-output">{error}</span>) : ''}
      <select
        id={name}
        name={name}
        className={(error) ? 'error' : ''}
        ref={selectRef}
        value={value}
        onChange={handleChange}
        onBlur={validation}
      >
        {
          options.map((current) => {
            return (<option key={current.value} value={current.value}>{current.display}</option>);
          })
        }
      </select>
    </label>
  );
};

Select.propTypes = {
  name: propTypes.string.isRequired,
  display: propTypes.string.isRequired,
  autofocus: propTypes.bool,
  options: propTypes.array,
  value: propTypes.string.isRequired,
  setValue: propTypes.func.isRequired,
  error: propTypes.string,
  validation: propTypes.func,
};

Select.defaultProps = {
  autofocus: false,
  options: [],
  error: '',
  validation: () => { },
};

export default Select;
