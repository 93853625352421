import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, checkEmail } from '../../actions/auth';
import Input from '../../components/Input';

const Register = (props) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const emailExists = useSelector((store) => { return store.auth.emailExists; });

  const [data, setRegisterData] = useState({
    email: '',
    password: '',
    confirm: '',
  });

  const setData = (property, value) => {
    setRegisterData(Object.assign({}, data, { [property]: value }));
  };

  // debounce checking email existence
  const [debounce, setDebounce] = useState(null);
  const debounceEmail = (value) => {
    if (debounce) { clearTimeout(debounce); }
    const timeout = setTimeout(() => { if (value) { dispatch(checkEmail(value)); } }, 1000);
    setDebounce(timeout);
  };

  // email
  const [emailError, setEmailError] = useState('');
  const emailValidation = ({ target: { value } }) => {
    if (!/\S+@\S+\.\S+/.test(value)) { setEmailError('Invalid Format'); }
    else {
      setEmailError('');
      debounceEmail(value);
    }
  };

  // password
  const [passwordError, setPasswordError] = useState('');
  const passwordValidation = ({ target: { value } }) => {
    if (!value) { setPasswordError('Required'); }
    else if (value.length < 8) { setPasswordError('Password must be at least 8 characters'); }
    else { setPasswordError(''); }
  };

  // confirm password
  const [confirmError, setConfirmError] = useState('');
  const confirmValidation = ({ target: { value } }) => {
    if (!value) { setConfirmError('Required'); }
    else if (value.length < 8) { setConfirmError('Password must be at least 8 characters'); }
    else if (value !== data.password) { setConfirmError('Passwords do not match'); }
    else { setConfirmError(''); }
  };

  // form validation
  const formValid = () => {
    return !emailError
      && !emailExists
      && !passwordError
      && !confirmError
      && data.email
      && data.password
      && data.confirm;
  };

  // submit form
  const [submitError, setSubmitError] = useState('');
  const submit = () => {
    // check form is valid
    if (!formValid()) { return undefined; }

    // prevent accidental double submission
    if (submitted) { return undefined; }
    setSubmitted(true);

    // submit form
    return dispatch(register(data.email.trim(), data.password))
      .then(() => { return props.history.push('/dashboard'); })
      .catch((err) => { setSubmitError(err.message); });
  };

  const finalEmailError = emailExists ? 'Email Already Exists' : emailError;

  return (
    <div className="container">
      <p className="page-header">Create An Account</p>

      <Input
        autofocus
        name="email"
        type="email"
        display="Email"
        maxLength="255"
        value={data.email}
        setValue={setData}
        error={finalEmailError}
        validation={emailValidation}
      />

      <Input
        name="password"
        type="password"
        display="Password"
        maxLength="255"
        value={data.password}
        setValue={setData}
        error={passwordError}
        validation={passwordValidation}
      />

      <Input
        name="confirm"
        type="password"
        display="Confirm Password"
        maxLength="255"
        value={data.confirm}
        setValue={setData}
        error={confirmError}
        validation={confirmValidation}
        onKeyPress={({ key }) => { if (key === 'Enter') { return submit(); } return undefined; }}
      />

      <button
        type="button"
        className="btn blue full"
        onClick={submit}
        disabled={!formValid() || submitted}
      >
        Register
      </button>

      {submitError ? (<div className="error-block">{submitError}</div>) : ''}
    </div>
  );
};

Register.propTypes = { history: propTypes.object.isRequired };

export default Register;
