/**
 * ACTIONS
 */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REDIRECT = 'REDIRECT';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const UPDATE_USER = 'UPDATE_USER';

/**
 * Action Creators
 */
export const login = (email, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: { 'Content-Type': 'application/json' },
  };

  return async (dispatch) => {
    const response = await fetch('/api/login', options);
    const body = await response.json();

    if (!response) { return Promise.reject(new Error('Connection Failed')); }
    if (response.status === 401) { return Promise.reject(new Error(body.message)); }
    if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

    return dispatch({
      type: LOGIN,
      payload: body,
    });
  };
};

export const logout = () => {
  const options = { method: 'GET' };

  return async (dispatch) => {
    const response = await fetch('/api/logout', options);
    const body = await response.json();

    if (!response) { return Promise.reject(new Error('Connection Failed')); }
    if (response.status === 401) { return Promise.reject(new Error(body.message)); }
    if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

    return dispatch({ type: LOGOUT });
  };
};

export function redirect(url) {
  return {
    type: REDIRECT,
    payload: url,
  };
}

export const register = (email, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: { 'Content-Type': 'application/json' },
  };

  return async (dispatch) => {
    const response = await fetch('/api/register', options);
    const body = await response.json();

    if (!response) { return Promise.reject(new Error('Connection Failed')); }
    if (response.status === 401) { return Promise.reject(new Error(body.message)); }
    if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

    return dispatch({
      type: REGISTER,
      payload: body,
    });
  };
};

export const checkEmail = (email) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return async (dispatch) => {
    const encodedEmail = encodeURIComponent(email);
    const response = await fetch(`/api/email?email=${encodedEmail}`, options);
    const body = await response.json();

    if (!response) { return Promise.reject(new Error('Connection Failed')); }
    if (response.status === 401) { return Promise.reject(new Error(body.message)); }
    if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

    return dispatch({
      type: CHECK_EMAIL,
      payload: body.exists,
    });
  };
};

export const forgot = async (email) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch('/api/forgot', options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const reset = async (token, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ token, password }),
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch('/api/reset', options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const confirm = async (token) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: { 'Content-Type': 'application/json' },
  };

  const response = await fetch('/api/confirm', options);
  const body = await response.json();

  if (!response) { return Promise.reject(new Error('Connection Failed')); }
  if (response.status === 401) { return Promise.reject(new Error(body.message)); }
  if (response.status !== 200) { return Promise.reject(new Error(body.message)); }

  return body;
};

export const updateUser = (user) => {
  return { type: UPDATE_USER, payload: user };
};
