import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from './Toggle.module.scss';

const Toggle = (props) => {
  const {
    name,
    options,
    disabled,
    value,
    setValue,
  } = props;

  useEffect(() => {
    if (value && disabled) { setValue(name, ''); }
    else if (!value && !disabled) { setValue(name, 'week'); }
  }, [name, value, setValue, disabled]);

  const genOptions = (option) => {
    return (
      <div key={option.id} className={styles.option}>
        <button
          type="button"
          disabled={disabled}
          className={['btn', 'full', (value === option.id ? 'blue' : 'gray')].join(' ')}
          onClick={() => { if (value !== option.id) { setValue(name, option.id); } }}
        >
          {`${option.id.toUpperCase()} (+$${option.price})`}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.toggleContainer}>
      {options.map(genOptions)}
    </div>
  );
};

Toggle.propTypes = {
  name: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  disabled: propTypes.bool.isRequired,
  value: propTypes.string.isRequired,
  setValue: propTypes.func.isRequired,
};

export default Toggle;
