import propTypes from 'prop-types';
import React from 'react';

import styles from './JobDetail.module.scss';

const JobDetail = ({ data, demo }) => {
  const genTitle = (position, company_name) => {
    if (position && company_name) {
      return (
        <div className={styles.jobHeader}>
          {data.position}
          {' '}
          at
          {' '}
          {data.company_name}
        </div>
      );
    }
    if (position) {
      return (
        <div className={styles.jobHeader}>
          {data.position}
        </div>
      );
    }
    return '';
  };

  const genJobSection = (title, content) => {
    if (!content) { return ''; }
    return (
      <section className={styles.jobInfo}>
        <div className={styles.sectionHeader}>
          {title}
        </div>

        <div className={styles.jobDetails}>
          {content}
        </div>
      </section>
    );
  };

  const genLocation = (title, content, remote) => {
    if (!content) { return ''; }
    return (
      <section className={styles.jobInfo}>
        <div className={styles.sectionHeader}>
          {title}
        </div>

        <div className={styles.jobDetails}>
          {content}
          {' '}
          {remote ? <span className={styles.remote}>(Remote Friendly)</span> : ''}
        </div>
      </section>
    );
  };

  const apply = () => {
    if (data.apply_url) { return data.apply_url; }
    return `mailto:${data.apply_email}`;
  };

  const generateApply = () => {
    if (demo) {
      return (
        <button type="button" className="btn full blue" disabled>
          Apply
        </button>
      );
    }

    return (
      <a href={apply()} target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn full blue">
          Apply
        </button>
      </a>
    );
  };

  return (
    <div className={styles.jobDetail}>
      {genTitle(data.position, data.company_name)}

      {genJobSection('Job Description', data.job_description)}

      {genJobSection('Responsibilities', data.responsibilities)}

      {genJobSection('Requirements', data.requirements)}

      {genLocation('Location', data.location, data.remote_ok)}

      {genJobSection('Contract Length', data.contract_length)}

      {genJobSection('Compensation', data.compensation)}

      <hr />

      <div className={styles.application}>
        <div className={styles.applicationHeader}>
          How to apply
        </div>

        <div className={styles.howToApply}>
          {data.how_to_apply}
        </div>

        {generateApply()}
      </div>
    </div>
  );
};

JobDetail.propTypes = {
  demo: propTypes.bool,
  data: propTypes.object.isRequired,
};

JobDetail.defaultProps = { demo: false };

export default JobDetail;
