import propTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styles from './SwitchInput.module.scss';

const SwitchInput = (props) => {
  const {
    name,
    data,
    setValue,
    error,
    validation,
  } = props;
  const [method, setMethod] = useState('url');

  const inputRef = useRef(null);

  useEffect(() => {
    if (data.apply_url) { setMethod('url'); }
    else if (data.apply_email) { setMethod('email'); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.apply_email, data.apply_url]);

  const toggleMethod = (e) => {
    const switchMethod = e.target.dataset.method;
    if (switchMethod === 'url') { setValue('apply_email', ''); }
    else if (switchMethod === 'email') { setValue('apply_url', ''); }
    setMethod(switchMethod);
    inputRef.current.focus();
  };

  // switch input
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (method === 'url') { setValue('apply_url', inputValue); }
    else if (method === 'email') { setValue('apply_email', inputValue); }

    if (method === 'url') { validation('apply', inputValue, 'url'); }
    else if (method === 'email') { validation('apply', inputValue, 'email'); }
  };

  // switch blur
  const handleBlur = (e) => {
    let value = '';
    const inputValue = e.target.value.trim();

    if (method === 'url') { value = data.apply_url; }
    else if (method === 'email') { value = data.apply_email; }

    if (inputValue !== value && method === 'url') { setValue('apply_url', inputValue); }
    else if (inputValue !== value && method === 'email') { setValue('apply_email', inputValue); }

    if (method === 'url') { validation('apply', inputValue, 'url'); }
    else if (method === 'email') { validation('apply', inputValue, 'email'); }
  };

  return (
    <label className={styles.switchInput} htmlFor={name}>
      <div className={styles.inputHeader}>
        Apply using:
        {' '}
        {error ? (<span className="error-output">{error}</span>) : ''}
        {method === 'email' ? (<div className="sub-label">This email is public</div>) : ''}
      </div>

      <div className={styles.buttonsContainer}>
        <div className={styles.split}>
          <button
            type="button"
            className={['btn', 'full', (method === 'url' ? 'blue' : 'gray')].join(' ')}
            data-method="url"
            onClick={toggleMethod}
          >
            URL
          </button>
        </div>

        <div className={styles.split}>
          <button
            type="button"
            className={['btn', 'full', (method === 'email' ? 'blue' : 'gray')].join(' ')}
            data-method="email"
            onClick={toggleMethod}
          >
            Email
          </button>
        </div>
      </div>

      <input
        type={method === 'url' ? 'text' : 'email'}
        name={name}
        ref={inputRef}
        maxLength={method === 'url' ? '2048' : '255'}
        className={(error) ? 'error' : ''}
        value={method === 'url' ? data.apply_url : data.apply_email}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </label>
  );
};

SwitchInput.propTypes = {
  name: propTypes.string.isRequired,
  data: propTypes.object.isRequired,
  setValue: propTypes.func.isRequired,
  error: propTypes.string,
  validation: propTypes.func,
};

SwitchInput.defaultProps = {
  error: '',
  validation: () => { },
};

export default SwitchInput;
