import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, redirectUrl, ...rest }) => {
  const loggedIn = useSelector((store) => { return !!store.auth.user; });

  return (
    <Route
      {...rest}
      render={(props) => {
        return (loggedIn ? (
          <Component {...props} />
        )
          : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location },
            }}
            />
          ));
      }
      }
    />
  );
};

PrivateRoute.propTypes = {
  location: propTypes.object,
  component: propTypes.func.isRequired,
  redirectUrl: propTypes.string,
};

PrivateRoute.defaultProps = {
  location: {},
  redirectUrl: '',
};

export default PrivateRoute;
